var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('v-card',{staticClass:"list"},[_c('div',{staticClass:"tools"},[_c('v-text-field',{attrs:{"label":"Filter Users","prepend-icon":"fal fa-search"}}),_c('v-btn',{staticClass:"add-btn",attrs:{"small":"","color":"primary"},on:{"click":_vm.createNewUser}},[_vm._v("Add")])],1),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedUserIndex),callback:function ($$v) {_vm.selectedUserIndex=$$v},expression:"selectedUserIndex"}},_vm._l((_vm.users),function(user){return _c('v-list-item',{key:user.userID,on:{"click":function($event){_vm.selectedUser = user}}},[_vm._v(" "+_vm._s(user.name_first)+" "+_vm._s(user.name_last)+" ")])}),1)],1),(Object.keys(_vm.selectedUser).length)?_c('div',{staticClass:"editing"},[[_c('div',{staticClass:"edit-user-tools"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.saveUser}},[_vm._v("Save")]),_c('div',{staticClass:"property-add"},[_c('v-text-field',{attrs:{"label":"Name of new property to add"},model:{value:(_vm.whatPropToAddName),callback:function ($$v) {_vm.whatPropToAddName=$$v},expression:"whatPropToAddName"}}),_c('v-select',{staticClass:"type-chooser",attrs:{"items":[
            {
              text: 'text',
              value: 'string'
            },
            {
              text: 'On/Off',
              value: 'boolean'
            }
        ]},model:{value:(_vm.whatPropToAdd),callback:function ($$v) {_vm.whatPropToAdd=$$v},expression:"whatPropToAdd"}}),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addProp}},[_vm._v("Add")])],1)],1),_vm._l((_vm.sortedSelectedUserKeys),function(key,i){return _c('div',{key:i,staticClass:"item"},[_c('div',{staticClass:"prop"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(key))])]),_c('div',{staticClass:"input-tool"},[(key === 'userID')?_c('v-text-field',{attrs:{"disabled":"","label":"Value:"},model:{value:(_vm.selectedUser[key]),callback:function ($$v) {_vm.$set(_vm.selectedUser, key, $$v)},expression:"selectedUser[key]"}}):(key === 'companyID')?_c('v-text-field',{attrs:{"disabled":"","label":"Company:"},model:{value:(_vm.selectedUser[key]),callback:function ($$v) {_vm.$set(_vm.selectedUser, key, $$v)},expression:"selectedUser[key]"}}):(typeof _vm.selectedUser[key] === 'string')?_c('v-text-field',{attrs:{"label":"Value:"},model:{value:(_vm.selectedUser[key]),callback:function ($$v) {_vm.$set(_vm.selectedUser, key, $$v)},expression:"selectedUser[key]"}}):(typeof _vm.selectedUser[key] === 'boolean')?_c('v-switch',{attrs:{"label":"On or Off?"},model:{value:(_vm.selectedUser[key]),callback:function ($$v) {_vm.$set(_vm.selectedUser, key, $$v)},expression:"selectedUser[key]"}}):_vm._e(),(key !== 'userID')?_c('v-icon',{staticClass:"fal fa-times",on:{"click":function($event){return _vm.deleteSelectedUserKey(key)}}}):_vm._e()],1)])})]],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }