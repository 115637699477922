<template>
  <div class="users">
    <v-card
        class="list"
    >
      <div class="tools">
        <v-text-field label="Filter Users" prepend-icon="fal fa-search"></v-text-field>
        <v-btn small color="primary" class="add-btn" @click="createNewUser">Add</v-btn>
      </div>
      <v-list-item-group color="primary" v-model="selectedUserIndex">
        <v-list-item v-for="user in users" :key="user.userID" @click="selectedUser = user">
          {{ user.name_first }}
          {{ user.name_last }}
        </v-list-item>
      </v-list-item-group>
    </v-card>
    <div class="editing" v-if="Object.keys(selectedUser).length">
      <template>
        <div class="edit-user-tools">
          <v-btn color="success" @click="saveUser">Save</v-btn>
          <div class="property-add">

            <v-text-field label="Name of new property to add" v-model="whatPropToAddName"/>
            <v-select
                class="type-chooser"
                :items="[
              {
                text: 'text',
                value: 'string'
              },
              {
                text: 'On/Off',
                value: 'boolean'
              }
          ]"
                v-model="whatPropToAdd"
            />
            <v-btn color="success" @click="addProp">Add</v-btn>
          </div>

        </div>
        <div class="item" :key="i" v-for="(key, i) in sortedSelectedUserKeys">
          <div class="prop">
            <div class="text">{{ key }}</div>
          </div>
          <div class="input-tool">
            <v-text-field v-if="key === 'userID'" disabled label="Value:" v-model="selectedUser[key]"/>
            <v-text-field v-else-if="key === 'companyID'" disabled label="Company:" v-model="selectedUser[key]"/>
            <v-text-field v-else-if="typeof selectedUser[key] === 'string'" label="Value:" v-model="selectedUser[key]"/>
            <v-switch v-else-if="typeof selectedUser[key] === 'boolean'" label="On or Off?"
                v-model="selectedUser[key]"/>
            <v-icon v-if="key !== 'userID'" class="fal fa-times" @click="deleteSelectedUserKey(key)" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { db, analytics } from "@/helpers/firebase";
import { uuid } from "@/helpers/uuid";

export default {
  name: 'Users',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'EMS Bio Users',
    meta: [
      {
        name: 'description',
        content: 'Manage Users'
      },
    ],
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  docs(v) {
    return {
      props: v.props,
      events: {},
      slots: {},
      components: v.components,
    };
  },
  props: {},
  data() {
    return {
      users: {},
      selectedUser: {},
      whatPropToAdd: null,
      whatPropToAddName: null,
      selectedUserIndex: null,
      // sortedSelectedUserProps: [],
      newUserTemplate: {
        userID: '',
        email: '',
        name_first: '',
        name_last: '',
        sales: false,
        introducedBy: '',
        assignee: '',
        terms: '',
        termsConditional: '',
        termsConditionalMonths: '',
        notes: '',
      },
    };
  },
  created() {
    this.getUsers();
  },
  // watch: {
  //   selectedUser: {
  //     deep: true,
  //     handler() {
  //       const toReturn = [];
  //       Object.keys(this.selectedUser).forEach(prop => {
  //         toReturn.push({
  //           prop,
  //           value: this.selectedUser[prop],
  //         });
  //       })
  //       this.sortedSelectedUserProps = toReturn.sort((a, b) => a.prop > b.prop ? 1 : -1);
  //     },
  //   }
  // },
  computed: {
    sortedSelectedUserKeys() {
      return Object.keys(this.selectedUser).sort();
    },
    // selectedPropsWithChanges() {
    //   const toReturn = {};
    //   this.sortedSelectedUserProps.forEach(obj => {
    //     toReturn[obj.prop] = obj.value;
    //   })
    //   return toReturn;
    // },
    // isSelectedSameAsOriginal() {
    //   const allProps = Object.keys(this.selectedPropsWithChanges);
    //   const currentUser = Object.keys(this.selectedUser);
    //   const sameLength = allProps.length === currentUser.length;
    //   if (!sameLength) return false;
    //
    //   return allProps.every(key => {
    //     const allVal = this.selectedPropsWithChanges[key]
    //     const currentVal = this.selectedUser[key];
    //     return allVal === currentVal;
    //   });
    // }
  },
  methods: {
    deleteSelectedUserKey(key) {
      if (key === 'userID') return;
      this.$delete(this.selectedUser, key);
    },
    addProp() {
      if (!this.whatPropToAdd || !this.whatPropToAddName) return;
      this.$set(this.selectedUser, this.whatPropToAddName, this.whatPropToAdd === 'boolean' ? true : '');
    },
    getUsers() {
      db.collection("users")
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // doc.data() is never undefined for query doc snapshots
              this.$set(this.users, doc.id, doc.data());
            });
          })
          .catch(function(error) {
            console.log("Error getting companies: ", error);
          });
    },
    saveUser() {
      let newUser = false;
      if (!this.selectedUser.userID) {
        this.selectedUser.userID = uuid();
        newUser = true;
      }
      db.collection('users')
          .doc(this.selectedUser.userID)
          .set(this.selectedUser)
          .then(() => {
            this.selectedUser = {};
            if (!newUser) this.$set(this.users, this.selectedUser.userID, this.selectedUser);
            if (newUser) analytics.logEvent('new user created');
          });

      if (newUser) {
        window.setTimeout(() => {
          this.getUsers()
        }, 2000)
      }

    },
    createNewUser() {
      this.selectedUser = JSON.parse(JSON.stringify(this.newUserTemplate));
    },
    changePropertyType(newType, value) {
      if (typeof value.value !== newType) {
        if (newType === "boolean") {
          value.value = true;
        } else {
          value.value = '';
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.users {
  display: flex;
  height: 100%;

  .editing {
    flex: 1;
    padding: 1rem 2rem;
    max-width: 600px;

    .edit-user-tools {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .type-chooser {
        width: 82px;
        margin: 0 1rem;
      }
      .property-add {
        display: flex;
        align-items: center;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prop {
        margin-right: 2rem;
        min-width: 200px;
      }

      .typeTool {
        margin: 0 1rem;
        display: flex;
        align-items: flex-end;
      }

      .input-tool {
        display: flex;
        flex: 1;
        justify-content: space-between;
      }
    }
  }

  .list {
    height: 100%;
    width: 300px;

    .tools {
      display: flex;
      align-items: center;

      .add-btn {
        margin: 0 1rem;
      }
    }
  }
}
</style>
